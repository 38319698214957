import styled from 'styled-components';

export const Wrapper = styled.div`
	font-size: 13px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 700;
	width: 80%;
	outline: 0;
`;

export const Backdrop = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 500;
	
`;

export const StyledModal = styled.div`
	z-index: 100;
	background: white;
	position: relative;
	margin: auto;
	border-radius: 8px;
	overflow: auto;
`;

export const Header = styled.div`
	position: absolute;
    right: 3px;
	border-radius: 8px 8px 0 0;
	display: flex;
	justify-content: space-between;
`;

export const HeaderText = styled.div`
	color: #fff;
	align-self: center;
	color: lightgray;
`;

export const CloseButton = styled.button`
	font-size: 1.8rem;
	border: none;
	border-radius: 3px;
	margin-left: 0.5rem;
	background: none;
	opacity: 0.5;
	:hover {
		cursor: pointer;
	}
`;

export const Content = styled.div`
	padding: 10px;
	max-height: 40rem;
`;