import { Component, useState, useEffect } from "react";
import landlordService from "../services/landlord.service";
import projectService from "../services/project.service";
import towerService from "../services/tower.service";
import refdataService from "../services/refdata.service";

import ILandlord from "../types/landlord.type";
import IProject from "../types/project.type"
import ITower from "../types/tower.type";

import { useSearchParamsState, SearchParamsStateType } from '../common/useSearchParamsState' 
import IRefData from "../types/refdata.type";
import { Modal } from "../modal/modal";
import { LandlordDetailModal } from "./landlord-detail-modal/landlord-detail-modal";
import { LandlordCreateModal } from "./landlord-create-modal/landlord-create-modal";

type Props = {
  //_projects:IProject[]
};

type State = {
  //landlords: ILandlord[];
}

const filtersDefaults: SearchParamsStateType = {
  name: { type: 'string', default: '' },
  phone_no: { type: 'string', default: '' },
  project_id: { type: 'string', default: '' },
  tower_id: { type: 'string', default: '' },
  tinhtrang_cd: { type: 'string', default: '' },
  so_pn: { type: 'string', default: '' },
  trangthai_cd: { type: 'string', default: '' },
  is_following: { type: 'boolean', default: false },
  is_reminder: { type: 'boolean', default: false },
  page_index: {type: 'number', default:1},

  floor_no: { type: 'string', default: '' },
  truc_can: { type: 'string', default: '' },
  gia_ban: { type: 'string', default: '' },
  gia_thue: { type: 'string', default: '' },
  dien_tich: { type: 'string', default: '' },
  create_by: { type: 'string', default: '' },
  update_by: { type: 'string', default: '' },
  ma_can: { type: 'string', default: '' },
  address: { type: 'string', default: '' },
  note: { type: 'string', default: '' },
  contract_note: { type: 'string', default: '' },
  exp_contract: { type: 'string', default: '' },
  loaihinh_cd: { type: 'string', default: '' },
  huong_cd: { type: 'string', default: '' },
  noithat_cd: { type: 'string', default: '' }
}

export default class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <div>
        <LandlordBoardSearch />
      </div>
    );
  }
}

const LandlordBoardSearch = () => {
  const [isShownDetailModal, setIsShownDetailModal] = useState<boolean>(false);
  const [isShownCreateModal, setIsShownCreateModal] = useState<boolean>(false);
  
  const onCloseDetailModal = () => setIsShownDetailModal(false);
  const onCloseCreateModal = () => setIsShownCreateModal(false);

  const [filterParams, setFilterParams] = useSearchParamsState(filtersDefaults)
  const [projects, setProjects] = useState<IProject[]>([])
  const [towers, setTowers] = useState<ITower[]>([])
  const [refDatas, setRefDatas] = useState<IRefData[]>([])

  const [landlords, setLandlords] = useState<ILandlord[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalFollowings, setTotalFollowings] = useState(0);
  const [totalReminders, setTotalReminders] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [landlordIdSelected, setLandlordIdSelected] = useState<number>(0);
  const [searchDatas, setSearchDatas] = useState<ILandlord>();

  let totalPages = Math.ceil(totalRecords / pageSize)

  useEffect(()=>{
    landlordService.getAll(filterParams).then(
      response => {
        setLandlords(landlord => (landlord= response.data.data));
        setTotalRecords(response.data.totalRecords);
        setTotalFollowings(response.data.totalFollowings);
        setTotalReminders(response.data.totalReminders);
        setPageSize(response.data.pageSize);
      },
      error => {
        //setLandlords([]);
      }
    );
  },[filterParams.name,
    filterParams.phone_no,
    filterParams.project_id,
    filterParams.tower_id,
    filterParams.tinhtrang_cd,
    filterParams.so_pn,
    filterParams.trangthai_cd,
    filterParams.is_following,
    filterParams.is_reminder,
    
    filterParams.floor_no,
    filterParams.truc_can,
    filterParams.gia_ban,
    filterParams.gia_thue,
    filterParams.dien_tich,
    filterParams.create_by,
    filterParams.update_by,
    filterParams.ma_can,
    filterParams.address,
    filterParams.note,
    filterParams.contract_note,
    filterParams.exp_contract,
    filterParams.loaihinh_cd,
    filterParams.huong_cd,
    filterParams.noithat_cd,
    
    filterParams.page_index])


  useEffect(()=>{
    setSearchDatas({
                    name: filterParams.name,
                    phoneNo: filterParams.phone_no,
                    //projectID: filterParams.project_id,
                    //towerID: filterParams.tower_id,
                    //filterParams.tinhtrang_cd,
                    soPhongNgu: filterParams.so_pn,
                    //filterParams.trangthai_cd,
                    //filterParams.is_following,
                    //filterParams.is_reminder,
                    
                    floorNo: filterParams.floor_no,
                    trucCan: filterParams.truc_can,
                    giaBan: filterParams.gia_ban,
                    giaThue: filterParams.gia_thue,
                    dienTich: filterParams.dien_tich,
                    createdBy: filterParams.create_by,
                    updatedBy: filterParams.update_by,
                    maCan: filterParams.ma_can,
                    address: filterParams.address,
                    note: filterParams.note,
                    contractNote: filterParams.contract_note,
                    expContract: filterParams.exp_contract,
                    //filterParams.loaihinh_cd,
                    //filterParams.huong_cd,
                    //filterParams.noithat_cd
                    });

    const fetchProjects = async () => {
      const projects = await projectService.getAll()
      setProjects(projects);
    };    
    fetchProjects();

    const fetchTowers = async () => {
			const towers = await towerService.getAll()
			setTowers(towers);
		};    
    fetchTowers();

    const fetchRefDatas = async () => {
			const refdatas = await refdataService.getAll()
			setRefDatas(refdatas);
		  };    
		fetchRefDatas();
  },[])

  return (
    <div>
      <Modal
          isShown={isShownDetailModal}
          hide={()=>setIsShownDetailModal(false)}
          headerText={landlordIdSelected+''}
          //landlordId={landlordIdSelected}
          modalContent={
            <LandlordDetailModal 
              onClose={onCloseDetailModal} 
              landlordId={landlordIdSelected}
              //landlordObj={landlords.find(l=>l.id === landlordIdSelected)}
            />
          }
        />

      <Modal
          isShown={isShownCreateModal}
          hide={()=>setIsShownCreateModal(false)}
          headerText={''}
          //landlordId={landlordIdSelected}
          modalContent={
            <LandlordCreateModal 
              onClose={onCloseCreateModal} 
              //landlordId={landlordIdSelected}
              //landlordObj={landlords.find(l=>l.id === landlordIdSelected)}
            />
          }
        />

      <div className="card text-white bg-primary mb-2">
        <div className="card-header">
          <i className="fas fa-plus"></i><b>DANH SÁCH</b>
          <div className="float-right">
            <div className="summary">Trình bày <b>{(filterParams.page_index-1)*pageSize + 1}-{Math.min((filterParams.page_index-1)*pageSize + pageSize, totalRecords)}</b> trong số <b>{totalRecords}</b> Khách Hàng.</div>
          </div>
        </div>
      </div>
      <div className="btn-toolbar kv-grid-toolbar toolbar-container float-right">
        <div className="btn-group mt-1">
          <a onClick={()=> setIsShownCreateModal(true) } className="btn btn-success">+ Tạo mới</a>
        </div>
      </div>
      <div>
        <div>
          <div className="btn-group mt-1 mr-2">
            <a onClick={() => setFilterParams({page_index: 1, is_following: false, is_reminder: false })} className={`${filterParams.is_following===false && filterParams.is_reminder===false?'active':''} btn btn-success`}>All</a>
            <a onClick={() => setFilterParams({page_index: 1, is_following: true, is_reminder: false })} className={`${filterParams.is_following===true?'active':''} btn btn-success`}>Đang Theo Dõi <span className="badge badge-light">{totalFollowings}</span></a>
            <a onClick={() => setFilterParams({page_index: 1, is_following: false, is_reminder: true })} className={`${filterParams.is_reminder===true?'active':''} btn btn-success`}>Nhắc Nhở <span className="badge badge-light">{totalReminders}</span></a>
          </div>
        </div>
        <div className="btn-group mt-1 mr-2" role="group" aria-label="Filter by Project">
          <a onClick={() => setFilterParams({page_index: 1, project_id: '', tower_id: '' })} className={`${filterParams.project_id===''?'active':''} btn btn-info`}>All</a>
          {
              projects.map(_project =>
                <a key={_project.id} onClick={() => setFilterParams({page_index: 1, project_id: _project.id, tower_id: '' })} className={`${filterParams.project_id===''+_project.id?'active':''} btn btn-info`}>{_project.name}</a>
              )
          }
        </div>

        <div className="btn-group mt-1 mr-2" role="group" aria-label="Filter by Tower">
          {filterParams.project_id!=''?<a onClick={() => setFilterParams({page_index: 1, tower_id: '' })} className={`${filterParams.tower_id===''?'active':''} btn btn-primary`}>All</a>:''}
          {
              towers.filter(_t => filterParams.project_id === ''+_t.projectID).map(_tower =>
                <a key={_tower.id} onClick={() => setFilterParams({page_index: 1, tower_id: _tower.id })} className={`${filterParams.tower_id===''+_tower.id?'active':''} btn btn-primary`}>{_tower.name}</a>
              )
          }
        </div>

        <div className="btn-group mt-1 mr-2" role="group" aria-label="Filter by Tinh Trang">
          <a onClick={() => setFilterParams({page_index: 1, tinhtrang_cd: '' })} className={`${filterParams.tinhtrang_cd===''?'active':''} btn btn-danger`}>All</a>
          {
              refDatas.filter(_r => 'tinh_trang' === ''+_r.refCd).map(_item =>
                <a key={_item.id} onClick={() => setFilterParams({ page_index: 1, tinhtrang_cd: _item.id })} className={`${filterParams.tinhtrang_cd===''+_item.id?'active':''} btn btn-danger`}>{_item.name}</a>
              )
          }
        </div>

        <div className="btn-group mt-1 mr-2" role="group" aria-label="Filter by So PN">
          <a onClick={() => setFilterParams({ page_index: 1, so_pn: '' })} className={`${filterParams.so_pn===''?'active':''} btn btn-secondary`}>PN</a>
          {
              refDatas.filter(_r => 'so_pn' === ''+_r.refCd).map(_item =>
                <a key={_item.id} onClick={() => setFilterParams({ page_index: 1, so_pn: _item.id })} className={`${filterParams.so_pn===''+_item.id?'active':''} btn btn-secondary`}>{_item.name}</a>
              )
          }
        </div>

        <div className="btn-group mt-1 mr-2" role="group" aria-label="Filter by Trang Thai">
          <a onClick={() => setFilterParams({ page_index: 1, trangthai_cd: '' })} className={`${filterParams.trangthai_cd===''?'active':''} btn btn-warning`}>All</a>
          {
              refDatas.filter(_r => 'trang_thai' === ''+_r.refCd).map(_item =>
                <a key={_item.id} onClick={() => setFilterParams({ page_index: 1, trangthai_cd: _item.id })} className={`${filterParams.trangthai_cd===''+_item.id?'active':''} btn btn-warning`}>{_item.name}</a>
              )
          }
        </div>
      </div>

      {/* <select onChange={(e) => setFilterParams({ orderBy: e.target.value })} value={filterParams.orderBy}>
        <option value='price'>Price</option>
        <option value='date'>Date</option>
      </select> */}
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover">
          <thead>
              <tr>
                  <th style={{ }}>Khách Hàng</th>
                  <th style={{ }}>Dự Án</th>
                  <th style={{ }}>Tháp</th>
                  <th style={{ }}>Tầng</th>
                  <th style={{ }}>Căn</th>
                  <th style={{ }}>Giá Bán</th>
                  <th style={{ }}>Giá Thuê</th>
                  <th style={{ }}>Diện Tích</th>
                  <th style={{ }}>Số PN</th>
                  <th style={{ }}>Tình Trạng</th>
                  <th style={{ }}>Loại Hình</th>
                  <th style={{ }}>TG Tạo</th>
                  <th style={{ }}>TG Sửa</th>
                  <th style={{ }}>Tạo Bởi</th>
                  <th style={{ }}>Sửa Bởi</th>
                  <th style={{ }}>Số ĐT</th>
                  <th style={{ }}>Mã Căn</th>
                  <th style={{ }}>Địa Chỉ</th>
                  <th style={{ }}>Hướng</th>
                  <th style={{ }}>Nội Thất</th>
                  <th style={{ }}>Ngày hết HĐ</th>
                  <th style={{ }}>Ghi Chú HĐ</th>
                  <th style={{ }}>Ghi Chú Khác</th>
              </tr>
              <tr>
                <td><input className="form-control" type='text' 
                    value={searchDatas?.name ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, name: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, name: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, name: searchDatas?.name }) }}
                    /></td>
                <td><select value={filterParams.project_id} onChange={(e)=> { setFilterParams({ project_id:e.target.value, tower_id:'', page_index:1}) } } className="form-control">
										<option value=""></option>
										{
											projects.map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
                </td>
                <td><select value={filterParams.tower_id} onChange={(e)=> { setFilterParams({ tower_id:e.target.value, page_index:1 }) } } className="form-control">
										<option value=""></option>
										{
											towers.filter(_r => filterParams.project_id === ''+_r.projectID).map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
                </td>
                <td><input className="form-control" type='text' 
                    value={searchDatas?.floorNo ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, floorNo: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, floor_no: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, floor_no: searchDatas?.floorNo }) }}
                    /></td>
                <td><input className="form-control" type='text' 
                    value={searchDatas?.trucCan ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, trucCan: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, truc_can: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, truc_can: searchDatas?.trucCan }) }}
                    /></td>
                <td><input className="form-control" type='text' 
                    value={searchDatas?.giaBan ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, giaBan: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, gia_ban: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, gia_ban: searchDatas?.giaBan }) }}
                    /></td>
                <td><input className="form-control" type='text' 
                    value={searchDatas?.giaThue ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, giaThue: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, gia_thue: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, gia_thue: searchDatas?.giaThue }) }}
                    /></td>
                <td><input className="form-control" type='text' 
                    value={searchDatas?.dienTich ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, dienTich: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, dien_tich: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, dien_tich: searchDatas?.dienTich }) }}
                    /></td>
                <td><select value={filterParams.so_pn} onChange={(e)=> { setFilterParams({ so_pn:e.target.value, page_index:1 }) } } className="form-control">
										<option value=""></option>
										{
											refDatas.filter(_r => 'so_pn' === _r.refCd).map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
                </td>
                <td><select value={filterParams.tinhtrang_cd} onChange={(e)=> { setFilterParams({ tinhtrang_cd:e.target.value, page_index:1 }) } } className="form-control">
										<option value=""></option>
										{
											refDatas.filter(_r => 'tinh_trang' === _r.refCd).map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
                </td>
                <td><select value={filterParams.loaihinh_cd} onChange={(e)=> { setFilterParams({ loaihinh_cd:e.target.value, page_index:1 }) } } className="form-control">
										<option value=""></option>
										{
											refDatas.filter(_r => 'loai_hinh' === _r.refCd).map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
                </td>
                <td></td>
                <td></td>
                <td><input className="form-control" type='text' 
                    value={searchDatas?.createdBy ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, createdBy: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, create_by: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, create_by: searchDatas?.createdBy }) }}
                    /></td>
                <td><input className="form-control" type='text' 
                    value={searchDatas?.updatedBy ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, updatedBy: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, update_by: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, update_by: searchDatas?.updatedBy }) }}
                    /></td>
               <td><input className="form-control" type='text' 
                    value={searchDatas?.phoneNo ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, phoneNo: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, phone_no: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, phone_no: searchDatas?.phoneNo }) }}
                    /></td>
                <td><input className="form-control" type='text' 
                    value={searchDatas?.maCan ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, maCan: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, ma_can: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, ma_can: searchDatas?.maCan }) }}
                    /></td>
                <td><input className="form-control" type='text' 
                    value={searchDatas?.address ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, address: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, address: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, address: searchDatas?.address }) }}
                    /></td>
                <td>
                  <select value={filterParams.huong_cd} onChange={(e)=> { setFilterParams({ huong_cd:e.target.value, page_index:1 }) } } className="form-control">
										<option value=""></option>
										{
											refDatas.filter(_r => 'huong' === _r.refCd).map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
                </td>
                <td>
                <select value={filterParams.noithat_cd} onChange={(e)=> { setFilterParams({ noithat_cd:e.target.value, page_index:1 }) } } className="form-control">
										<option value=""></option>
										{
											refDatas.filter(_r => 'noi_that' === _r.refCd).map(_item =>
												<option key={_item.id} value={_item.id}>{_item.name}</option>
											)
										}
									</select>
                </td>
                <td></td>
                <td><input className="form-control" type='text' 
                    value={searchDatas?.contractNote ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, contractNote: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, contract_note: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, contract_note: searchDatas?.contractNote }) }}
                    /></td>
                <td><input className="form-control" type='text' 
                    value={searchDatas?.note ?? ''} 
                    onChange={(e) => setSearchDatas({...searchDatas, note: e.target.value })}
                    onBlur={(e)=> setFilterParams({page_index: 1, note: e.target.value})}
                    onKeyDown={(e)=> { if(e.key==='Enter') setFilterParams({page_index: 1, note: searchDatas?.note }) }}
                    /></td>
              </tr>
          </thead>
          <tbody>
        {landlords.map(landlord =>
          <tr key={landlord.id}>
              <td>
                <ul>
                  <li><a onClick={()=>{setLandlordIdSelected(landlord.id ?? 0); setIsShownDetailModal(true)}}><b>{landlord.name}</b></a></li>
                  <li><a href={`mailto:${landlord.email}`}>{landlord.email}</a></li>
                  <li>
                    <a href={`tel:${landlord.phoneNo}`}>{landlord.phoneNo}</a>
                    <a className="mr-2 d-none d-sm-inline" href={`zalo://conversation?phone=${landlord.phoneNo}`} target="_blank"> ℹ️</a>
                    <a className="mr-2 d-inline d-sm-none" href={`https://zalo.me/${landlord.phoneNo}`} target="_blank"> ℹ️</a></li>
                </ul>
              </td>
              <td>{landlord.projectName}</td>
              <td>{landlord.towerName}</td>
              <td>{landlord.floorNo}</td>
              <td>{landlord.trucCan}</td>
              <td>{landlord.giaBan}</td>
              <td>{landlord.giaThue}</td>
              <td>{landlord.dienTich}</td>
              <td>{landlord.soPhongNgu}</td>
              <td>{landlord.tinhTrang}</td>
              <td>{landlord.loaiHinh}</td>
              <td>{landlord.createdDate}</td>
              <td>{landlord.updatedDate}</td>
              <td>{landlord.createdBy}</td>
              <td>{landlord.updatedBy}</td>
              <td>{landlord.phoneNo}</td>
              <td>{landlord.maCan}</td>
              <td>{landlord.address}</td>
              <td>{landlord.huong}</td>
              <td>{landlord.noiThat}</td>
              <td>{landlord.expContractFormat?landlord.expContractFormat.toString():''}</td>
              <td>{landlord.contractNote}</td>
              <td>{landlord.note}</td>
              {/* <td style={{ whiteSpace: 'nowrap' }}>
              </td> */}
          </tr>
        )}
    </tbody>
        </table>
      </div>  
      <ul className="pagination">
            <li
              onClick={()=>{
                let _pg: number = +filterParams.page_index;
                if(_pg > 1)
                  setFilterParams({page_index: (_pg - 1)})
              }}
              className={`page-item ${filterParams.page_index === 1 ? 'disabled' : ''}`}
            >
              <a className="page-link">Previous</a>
            </li>
            {/* @ts-ignore */}
            {[...Array(totalPages).keys()].map((el) => (
              (el+1 > (filterParams.page_index-4) && (el+1<filterParams.page_index+4))?
              <li
                onClick={() => {
                  setFilterParams({ page_index: (el+1) });
                }}
                key={el}
                className={`page-item ${filterParams.page_index === (el + 1) ? 'active' : ''}`}
              >
                <a className="page-link">{el + 1}</a>
              </li>
              : ''
            ))}
            <li
              onClick={()=>{
                let _pg: number = +filterParams.page_index;
                if(_pg < totalPages)
                  setFilterParams({page_index: (_pg + 1)})
              }}
              className={`page-item ${filterParams.page_index === totalPages ? 'disabled' : ''}`}
            >
               <a className="page-link">Next</a>
            </li>
          </ul>
    </div>
  )
}