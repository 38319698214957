import { Navigate } from "react-router-dom";
import authService from "../../services/auth.service";


interface IProps {
  children: any;
}

export default function PrivateRoute({
children 
}: IProps) {
  const userAuth = authService.getCurrentUser();

  return (
    userAuth ? children : <Navigate to="/login"/>
  );
}
