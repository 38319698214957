import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://api.ytecaocap.com/";

class UserService {
  getPublicContent() {
    //return axios.get(API_URL + 'users');
    return '';
  }

  getUserBoard() {
    return axios.get(API_URL + 'users', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'users', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'users', { headers: authHeader() });
  }
}

export default new UserService();
