import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://api.ytecaocap.com/";

class ProjectService {
  getAll = async() => {
    const projectsStr = localStorage.getItem("_projects");
    if (projectsStr) 
      return JSON.parse(projectsStr);
    else
    {
      console.log('Projects get data api calling');
      return axios.get(API_URL + 'projects', { headers: authHeader() })
        .then(response => {
          localStorage.setItem("_projects", JSON.stringify(response.data));
          return response.data;
        });
    }
  }
}

export default new ProjectService();
