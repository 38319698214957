import axios from 'axios';
import ILandlord from '../types/landlord.type';
import authHeader from './auth-header';

const API_URL = "https://api.ytecaocap.com/";

class LandlordService {
  getAll(searchObj:URLSearchParams) {
    const queryString = new URLSearchParams(searchObj).toString();
    console.log('Landlord data api calling: ' + queryString)
    return axios.get(API_URL + 'landlords?' + queryString, { headers: authHeader() });
  }

  getById(id:number){
    console.log('Landlord get API by id');
    return axios.get(API_URL + 'landlords/' + id, { headers: authHeader() });
  }

  update(obj?:ILandlord){
    console.log(obj);
    return axios.post(API_URL + 'landlords/' + obj?.id, obj, { headers: authHeader() })
  }

  create(obj?:ILandlord){
    console.log(obj);
    return axios.post(API_URL + 'landlords', obj, { headers: authHeader() })
  }
}

export default new LandlordService();
