import authService from "../services/auth.service";
import { Link } from "react-router-dom";
import EventBus from "../common/EventBus";


export default function Nav() {
  const authUser = authService.getCurrentUser();

  const logOut = () => {
    authService.logout();
  }

  // only show nav when logged in
  if (!authUser) return null;

  return (
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">
            CENTRAL HOMES
          </Link>
          <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {authUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={logOut}>
                  LogOut
                </a>
              </li>
            </div>
        </nav>
  );
}
