import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://api.ytecaocap.com/";

class RefDataService {
  getAll = async() =>  {
    const refdatasStr = localStorage.getItem("_refdatas");
    if(refdatasStr)
      return JSON.parse(refdatasStr);
    else
    {
      console.log('RefDatas get data api calling');
      return axios.get(API_URL + 'refdatas', { headers: authHeader() })
        .then(response => {
          localStorage.setItem("_refdatas", JSON.stringify(response.data));
          return response.data;
        });
    }
  }
}

export default new RefDataService();
