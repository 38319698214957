import { Component } from "react";

//import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import IUser from "../types/user.type";
import userService from "../services/user.service";

type Props = {};

type State = {
  users: IUser[];
}

export default class BoardUser extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      users: []
    };
  }

  componentDidMount() {
    userService.getUserBoard().then(
      response => {
        this.setState({
          users: response.data
        });
      },
      error => {
        this.setState({
          
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  render() {
    return (
      <div className="container">
          <div>
            <ul>
              {this.state.users.map(user => (
                <li key={user.id}>{user.username}</li>
              ))}
            </ul>
          </div>
      </div>
    );
  }
}
