import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://api.ytecaocap.com/";

class LandlordLogService {
  getByLandlordId(landlord_id:number) {
    console.log(landlord_id)
    return axios.get(API_URL + 'landlordlogs?landlord_id=' + landlord_id, { headers: authHeader() });
  }

  addLandlordLog(landlord_id:number, description: string){
    return axios.post(API_URL + 'landlordlogs', { landlordID: landlord_id,  description}, { headers: authHeader() })
  }
}

export default new LandlordLogService();
