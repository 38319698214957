import axios from 'axios';
import authHeader from './auth-header';
import ITower from '../types/tower.type'

const API_URL = "https://api.ytecaocap.com/";

class TowerService {
  getAll = async() =>  {
    const towersStr = localStorage.getItem("_towers");
    if (towersStr) 
      return JSON.parse(towersStr);
    else
    {
      console.log('Towers get data api calling');
      return axios.get(API_URL + 'towers', { headers: authHeader() })
          .then(response => {
            localStorage.setItem("_towers", JSON.stringify(response.data));
            return response.data;
          });
    }
  }
}

export default new TowerService();
